import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'

import OpenHouse from '../OpenHouse'
import { PlaceHolderHouseUrl } from '../../Svg'
import { ThreeDTour, VideoTour } from '../../Svg'

const CardImage = ({
  containsVideoTour,
  containsZillow3dTour,
  statusInfo,
  featuredImageUrl,
  closestOpenHouseDate,
  closestOpenHouseTime
}) => {
  const isOpenHouse = closestOpenHouseDate && closestOpenHouseTime
  return (
    <div className='CardDesktop-imageContainer'>
      <div className='CardDesktop-imgOverlay'>
        <div
          className={cn('CardDesktop-imgOverlayInner', { 'CardDesktop-imgOverlayInner--up': isOpenHouse })}
        >
          {containsVideoTour && (
            <VideoTour className='CardDesktop-icon' />
          )}
          {containsZillow3dTour && (
            <ThreeDTour className='CardDesktop-icon' />
          )}
        </div>
      </div>

      {statusInfo?.label && (
        <div className='CardDesktop-status'>
          <div className={`StatusFlagDesktop StatusFlagDesktop--${statusInfo.type}`}>
            <div className='StatusFlagDesktop-text'>{statusInfo.label}</div>
          </div>
        </div>
      )}

      <picture className='CardDesktop-aspectRatioContent'>
        <source srcSet={featuredImageUrl?.webp} type='image/webp' />
        <img
          src={featuredImageUrl?.jpeg}
          className='CardDesktop-image'
          data-sprinkle='replace-broken-image'
          data-replace-broken-image-url={PlaceHolderHouseUrl}
          alt=''
        />
      </picture>

      {isOpenHouse && (
        <OpenHouse closestOpenHouseDate={closestOpenHouseDate} closestOpenHouseTime={closestOpenHouseTime} />
      )}
    </div>
  )
}

CardImage.propTypes = {
  containsVideoTour: PropTypes.bool,
  containsZillow3dTour: PropTypes.bool,
  statusInfo: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string
  }),
  featuredImageUrl: PropTypes.shape({
    webp: PropTypes.string,
    jpeg: PropTypes.string
  }),
  closestOpenHouseDate: PropTypes.string.isRequired,
  closestOpenHouseTime: PropTypes.string.isRequired
}

export default CardImage
