import PropTypes from 'prop-types'
import React from 'react'

const LISTING_CLASS = {
  sale: 'Sale',
  rental: 'Rental'
}

const TEXT_FOR_HIDDEN_ADDRESS = '(undisclosed address)'

const CardContent = ({
  address,
  undisclosedAddressText,
  saved,
  id,
  listingClass,
  heading,
  townName,
  viewed,
  price,
  currentPricePeriodLabel,
  land,
  beds,
  bathsDisplay,
  acreage,
  showAcreage,
  showSqft,
  sqft,
  byOwner,
  listingBy,
  keyAmenityLabel,
  links
}) => {
  const showDetailsByListingClass =
    (listingClass === LISTING_CLASS.sale && !land) ||
    (listingClass === LISTING_CLASS.rental)

  const isAddressHiden = address?.toLowerCase() === TEXT_FOR_HIDDEN_ADDRESS

  return (
    <div className='CardDesktop-content'>
      <div className='CardDesktop-mainContent'>
        <div className='CardDesktop-headerContent'>
          <div className='CardDesktop-priceContent'>
            <div
              className={`CardDesktop-price${viewed ? ' CardDesktop-price--viewedPrice' : ''}`}
              data-sprinkle='update-viewed-listings'
              data-listing-id={id}
            >
              {price}
            </div>
            {listingClass === LISTING_CLASS.rental && (
              <p className='CardDesktop-period'>{`(${currentPricePeriodLabel})`}</p>
            )}
          </div>
          <div className='CardDesktop-saveAction' data-sprinkle='save-listing'>
            <button
              className={`Button Button--saveLink${ saved ? ' isActive' : ''}`}
              data-save-listing-button
              data-url={links?.save_action?.url}
              data-id={id}
              data-method={links?.save_action?.method}
              data-listing-class={listingClass}
            />
          </div>
        </div>

        <div className='CardDesktop-detailInfo'>
          <ul className='CardDesktop-detailGroup'>
            {showDetailsByListingClass && (
              <React.Fragment>
                <li className='CardDesktop-detailItem'>
                  <span className='CardDesktop-itemNumber'>{beds}</span>
                  <span className='CardDesktop-itemUnit'>Bd</span>
                </li>
                <li className='CardDesktop-detailItem'>
                  <span className='CardDesktop-itemNumber'>{bathsDisplay}</span>
                  <span className='CardDesktop-itemUnit'>Ba</span>
                </li>
              </React.Fragment>
            )}
            {showAcreage && (
              <li className='CardDesktop-detailItem'>
                <span className='CardDesktop-itemNumber'>{acreage}</span>
                <span className='CardDesktop-itemUnit'>Acr</span>
              </li>
            )}
            {showDetailsByListingClass && showSqft && (
              <li className='CardDesktop-detailItem'>
                <span className='CardDesktop-itemNumber'>{sqft}</span>
                <span className='CardDesktop-itemUnit'>Sq Ft</span>
              </li>
            )}
          </ul>
        </div>

        <div className='CardDesktop-addressContent'>
          {(address && !isAddressHiden) ? (
            <React.Fragment>
              <h2 className='CardDesktop-region'>
                {heading || townName}
              </h2>
              <div className='CardDesktop-circular' />
              <div className='CardDesktop-address'>
                {address}
              </div>
            </React.Fragment>
          ) : (
            <p className='CardDesktop-undisclosedAddress'>{undisclosedAddressText}</p>
          )}
        </div>
        {keyAmenityLabel && (
          <div className='CardDesktop-keyAmenity'>
            <span className='CardDesktop-label'>{keyAmenityLabel}</span>
          </div>
        )}
      </div>

      <div className='CardDesktop-listingBy withEllipsis'>
        {byOwner ? 'For Sale By Owner' : listingBy}
      </div>
    </div>
  )
}

CardContent.propTypes = {
  id: PropTypes.number,
  address: PropTypes.string,
  undisclosedAddressText: PropTypes.string,
  heading: PropTypes.string,
  townName: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentPricePeriodLabel: PropTypes.string,
  viewed: PropTypes.bool,
  saved: PropTypes.bool,
  land: PropTypes.bool,
  beds: PropTypes.number,
  bathsDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showAcreage: PropTypes.bool,
  acreage: PropTypes.number,
  showSqft: PropTypes.bool,
  sqft: PropTypes.number,
  keyAmenityLabel: PropTypes.string,
  byOwner: PropTypes.bool,
  listingBy: PropTypes.string,
  listingClass: PropTypes.string,
  links: PropTypes.shape({
    save_action: PropTypes.shape({
      url: PropTypes.string,
      method: PropTypes.string
    })
  })
}

export default CardContent
