import React from 'react'
import PropTypes from 'prop-types'

const NoResultsInfo = () => {
  return (
    <>
    <div className='NoResultsInfo'>
      <p className='NoResultsInfo-information'>No matching results</p>
      <p className='NoResultsInfo-recomendation'>Please try entering different search criteria</p>
    </div>
    <div className='NoResultsInfo-line' />
    </>
  )
}

NoResultsInfo.propTypes = {
  collection: PropTypes.array,
  header: PropTypes.string.isRequired,
  useHandDrawing: PropTypes.bool,
  usePropertyPolygons: PropTypes.bool
}

export default NoResultsInfo
